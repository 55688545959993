@import "./variables.module";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }
}

// Reset
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

// ol, ul {
// 	list-style: none;
// }
blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  user-select: none;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family:
    $mainFont,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;

  // overflow: hidden;
}

body {
  background: $primaryBackgroundColor;
  max-height: 100vh;
  //overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  background-origin: border-box !important;
  @include scrollbars(0.5em, $btnFill, transparent);
}

html,
body,
#__next {
  width: 100vw;
  height: 100%;
}

html,
body,
.body-content-container,
#__next {
  overflow-x: hidden;

  // disable scrollbar
  &::-webkit-scrollbar {
    display: none;
  }

  & {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  &.dragging {
    overflow: hidden !important;
  }
}

button {
  outline: none;
}

.site-container {
  height: 100%;
  width: 100%;
  position: relative;
}

.main-body-container {
  position: absolute;
  top: 68px;
  width: 100%;
  padding-left: 240px;
  margin-right: 10px;
  justify-content: center;
  transition: padding 50ms $easeInOut3;

  @media (max-width: $breakPointOne) {
    top: 120px;
    padding-left: 0px;
  }

  &.active-chat {
    padding-right: 360px;

    .body-content-container {
      padding-left: $padding;
    }

    @media (max-width: $breakPointOne) {
      padding-right: 0;

      .body-content-container {
        margin-right: 0;
        padding-right: $padding;
        padding-left: auto;
      }
    }
  }

  &.mini-side-bar {
    @media screen and (min-width: 1101px) {
      padding-left: 80px;
    }
  }

  &.sports-page {
    .body-content-container .inner-body-container {
      border-radius: 6px;
      padding: 0 20px;
    }
  }

  &.mobile-landing {
    top: 0 !important;
    padding: 0px !important;
    background-image: url("../assets/Images/mobile-landing.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top left;
    overflow: hidden;

    @media screen and (max-width: 600px) {
      background-image: url("../assets/Images/mobile-landing1.png");
      background-position: center center;
    }
  }
  .body-content-container {
    height: 100%;
    padding: 20px;
    display: flex;
    position: relative;
    justify-content: center;
    transition:
      padding 100ms $easeInOut3,
      margin 100ms $easeInOut3;

    &.mobile-landing {
      padding: 0px !important;
      height: 100vh;
    }
    .body-content {
      min-height: min-content;
    }

    .inner-body-container {
      height: 100%;
      width: 100%;
      max-width: 1200px;
    }
  }

  &.challenges-page-container {
    justify-content: flex-start;

    .body-content-container {
      padding: 0px 55px;

      @media (max-width: $breakPointOne) {
        padding: 0px 16px;
      }
      .inner-body-container {
        max-width: 1500px;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .active-chat {
    padding-right: 0;
  }
}

.rewards-gradient-text {
  background: linear-gradient(180deg, #fff 42.5%, #d0d0d0 80%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.page-transition-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

body {
  font-weight: 500;
  font-family: $mainFont, sans-serif;
}

input,
textarea,
button,
select,
option {
  font: inherit;
}

select,
option {
  cursor: pointer;
}

select option[disabled] {
  display: none;
}

body {
  font-family: $mainFont, sans-serif;
}

p,
h1,
h2,
h3,
h4,
a,
button {
  color: #fff;
  font-family: $mainFont;
}

li {
  font-family: $mainFont;
}

h1 {
  font-size: $fontSizeOne;
  line-height: 1.1;
}

h2 {
  line-height: 1.1;
}

h4,
h5,
h6 {
  font-size: 16px;
  font-family: $mainFont;
  color: $fontColorTwo;
}

p {
  line-height: 1.5;
  font-family: $mainFont;
}

button {
  background-color: transparent;
  padding: 0;
}

span {
  color: #fff;
  font-family: $mainFont;
}

blockquote {
  border-radius: 20px;
  background-color: $darkLineColor;
  margin-top: 20px;
  margin-bottom: 0px;
  padding: 20px;
  padding-left: 30px;
  padding-right: 30px;

  p:first-child {
    padding-top: 0px;
  }
}

//Tippy stuff
.tippy-box[data-theme~="custom-theme"] {
  background-color: #171d3b;
  padding: 0px;
  font-family: $mainFont;

  .tippy-arrow {
    color: #171d3b;
  }

  .tippy-content {
    padding-top: 3px;
  }
}

//Tippy stuff
.tippy-box[data-theme~="custom-flyout-theme"] {
  background-color: #171d39;
  padding: 0px;
  border-radius: 9px;
  left: 2px;
  top: -12px;

  .tippy-arrow {
    position: absolute;
    top: 10px !important;
    width: 40px;
    height: 38px;
    background: #171d3b;
    color: transparent;
    transform: translateX(-30px) !important;
  }

  .tippy-content {
    padding: 5px 4px !important;
  }
}

//Tippy stuff
.tippy-box[data-theme~="custom-theme-hover"] {
  background-color: #212749;
  background: rgba(13, 15, 27, 0.9);
  border-radius: $radius;
  font-family: $mainFont;

  padding: 0px;
  z-index: 10005 !important;

  .tippy-arrow {
    color: #212749;
    color: rgba(13, 15, 27, 0.9);
  }

  // .tippy-content {}
}

.tippy-box[data-theme~="custom-theme-hover-register"] {
  background-color: #2a315b;
  border-radius: $radius;
  font-family: $mainFont;
  padding: 0px;
  z-index: 10005 !important;
  // left: 10px;

  .tippy-arrow {
    color: #2a315b;
  }
  .tippy-content {
    padding: 0px;
  }
}

.custom-flyout-class {
  position: relative;
  margin-top: -10px;
  top: 2px !important;
}

.custom-on-hover-tippy-class {
  z-index: 9999999 !important;
  background-color: transparent;
}

.tippy-top {
  z-index: 9999999 !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.green-highlight {
  color: #90ff30 !important;
}

.yellow-highlight {
  color: #ffde30;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

[data-hidden="true"] {
  display: none !important;
}

hr {
  opacity: 0.3;
  border: 0;
  height: 1px;
  background-color: $fontColorSeven;
  border-radius: $radius;
}

.body-content-container.blog {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 3rem;
}

.favicon-container {
  border-radius: 8px;
  background-color: #191f3b;
}

/* Custom Theme for highlight.js */

.hljs {
  display: block;
}

.language-xml .hljs-meta,
.language-xml .hljs-meta-string {
  font-weight: bold;
  font-style: italic;
  color: #69f;
}

.hljs-comment,
.hljs-quote {
  font-style: normal !important;
  color: #9c6;
}

.hljs-name,
.hljs-keyword,
.hljs-built_in {
  color: #a7a;
}

.hljs-string {
  font-weight: normal;
}

.hljs-code,
.hljs-string,
.hljs-meta .hljs-string,
.hljs-number,
.hljs-regexp,
.hljs-link {
  color: #bce;
}

.hljs-title.class_,
.hljs-class .hljs-title,
.hljs-type {
  color: #96c;
}

.hljs-title.function_,
.hljs-function .hljs-title,
.hljs-attr,
.hljs-subst,
.hljs-tag {
  color: #fff;
}

.hljs-formula {
  background-color: #eee;
  font-style: normal !important;
}

.hljs-addition {
  background-color: #797;
}

.hljs-deletion {
  background-color: #c99;
}

.hljs-meta {
  color: #69f;
}

.hljs-section,
.hljs-selector-id,
.hljs-selector-class,
.hljs-selector-pseudo,
.hljs-selector-tag {
  color: #69f;
}

.hljs-selector-pseudo {
  font-style: normal !important;
}

.hljs-doctag,
.hljs-strong {
  font-weight: bold;
}

.hljs-emphasis {
  font-style: normal !important;
}

.hljs-title.function_ {
  color: #47a5f3 !important;
}
.hljs-title.class_ {
  font-weight: 400 !important;
  color: #d8a24f !important;
}
.hljs-params {
  color: #d8a24f !important;
}
.hljs-property {
  color: #cc5d6b !important;
}
.hljs-attr,
.hljs-type,
.hljs-doctag {
  font-weight: 400 !important;
}

// SELECT STYLES

select {
  /* Gradient background with custom dropdown arrow */
  background:
    radial-gradient(
      76.33% 100% at 50% 100%,
      rgb(38, 44, 82, 0.8) 0%,
      rgba(38, 44, 82, 0) 100%
    ),
    rgba(255, 255, 255, 0.05);
  appearance: none; /* Removes default browser arrow */
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 5L9 1' stroke='%23565C7D' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat, no-repeat;
  background-position:
    right 15px center,
    center;
  background-size:
    10px,
    100% 100%;

  &[aria-invalid="true"] {
    border-color: #ec4848;
  }

  option {
    border-radius: 6px;
    background: #0f1328;
    color: #e8e5ff;
  }
}

.min-safe-h-screen {
  /* equivalent to 100dvh in major browsers */
  min-height: calc(
    100vh - env(safe-area-inset-bottom, 0) - env(safe-area-inset-top, 0)
  );
}

@supports (-webkit-touch-callout: none) {
  .min-safe-h-screen {
    /* for ios safari 15, safe-area-inset-bottom is 0, so a special fix apply here */
    min-height: -webkit-fill-available;
  }
}
